import { Navigate, Outlet, RootRoute, Route, Router, RouterProvider, redirect, useNavigate } from '@tanstack/react-router'

import { GlobalContext, GlobalProvider } from 'context/GlobalContext'
import * as api from 'util/api'
import useAuth from 'hooks/useAuth'
import { useContext, useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import NiceModal from '@ebay/nice-modal-react'
import haxios, { getApir, getToken, getCinfo, cinfo } from 'util/haxios'
import 'util/modals'

import Layout from 'components/Layout'
import LoginPage from 'components/login/LoginPage'
import LoadingPage from 'components/loading/LoadingPage'
import RunsPage from 'components/runs/RunsPage'
import AssignmentPage from 'components/assignment/AssignmentPage'
import ActivePage from 'components/active/ActivePage'
import ActiveRunDetailsPage from 'components/active/ActiveRunDetailsPage'
import VehiclesPage from 'components/vehicles/VehiclesPage'
import CustomersPage from 'components/customers/CustomersPage'
import PodsPage from 'components/pods/PodsPage'
import PaymentsPage from 'components/payments/PaymentsPage'
import ReturnsPage from 'components/returns/ReturnsPage'
import RewindPage from 'components/rewind/RewindPage'
import ManifestsPage from 'components/manifests/ManifestsPage'
import BulletinsPage from 'components/bulletins/BulletinsPage'
import DashboardPage from 'components/dashboard/DashboardPage'
import Watch from 'components/Watch'

const topbar = require('./topbar')

topbar.config({
  shadowBlur: 1,
  barThickness: 2,
  barColors: {
    0: 'rgb(59, 130, 246)',
    1: 'rgb(59, 130, 246)',
  }
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 30000,
      //refetchOnMount: false,
      //refetchOnWindowFocus: false
      //keepPreviousData: true
    }
  }
})

const RequireAuth = () => {
  const navigate = useNavigate()
  const { setCinfo, setBrandingBlob, setLoginError } = useContext(GlobalContext)
  const [isLoading, setLoading] = useState(true)
  const [isReady, setReady] = useState(false)
  const { isAuthed } = useAuth()

  useEffect(() => {
    if (isAuthed === null) return

    if (isAuthed === false) {
      setLoading(false)
      return
    }

    (async () => {
      const cinfo = await api.getCompanyInfo()

      if (!cinfo) {
        setLoading(false)
        return
      }

      try {
        await getApir(cinfo.CompanyID)
      } catch (e) {
        setLoginError(e.message)
        navigate({ to: '/login' })
        setReady(true)
        setLoading(false)
        return
      }

      setCinfo(cinfo)
      const ciImage = await api.getImageFromBlobId(cinfo.CompanyBrandingBlobStorageID)
      setBrandingBlob(ciImage)
      if (window.location.pathname === '/login') navigate({ to: '/' })
      setReady(true)
      setLoading(false)
    })()
  }, [isAuthed])

  if (isLoading) {
    return <LoadingPage />
  }

  if (isReady) {
    return <Outlet />
  }

  return <Navigate to="/login" />
}

const authLoader = async () => {
  await getCinfo()
  await getApir(cinfo.CompanyID)

  try {
    const res = await haxios.get(process.env.REACT_APP_BASE_URL + '/api/authenticate/expired')
    if (res.error) throw res.error
    return true
  } catch (e) {
    console.log(e)

    throw redirect({
      to: '/login'
    })
  }
}

const rootRoute = new RootRoute()
const authRoute = new Route({ getParentRoute: () => rootRoute, id: 'auth', component: RequireAuth, beforeLoad: authLoader })
const loginRoute = new Route({ getParentRoute: () => rootRoute, path: '/login', component: LoginPage })
const layoutRoute = new Route({ getParentRoute: () => authRoute, id: 'layout', component: Layout })
const assignmentRoute = new Route({ getParentRoute: () => layoutRoute, path: '/', component: AssignmentPage, })
const runsRoute = new Route({ getParentRoute: () => layoutRoute, path: '/runs', component: RunsPage })
const customersRoute = new Route({ getParentRoute: () => layoutRoute, path: '/customers', component: CustomersPage })
const activeRoute = new Route({ getParentRoute: () => layoutRoute, path: '/active', component: ActivePage })
const activeRunDetailsRoute = new Route({ getParentRoute: () => layoutRoute, path: '/active/$runId', component: ActiveRunDetailsPage })
const vehiclesRoute = new Route({ getParentRoute: () => layoutRoute, path: '/vehicles', component: VehiclesPage })
const podsRoute = new Route({ getParentRoute: () => layoutRoute, path: '/pods', component: PodsPage })
const paymentsRoute = new Route({ getParentRoute: () => layoutRoute, path: '/payments', component: PaymentsPage })
const returnsRoute = new Route({ getParentRoute: () => layoutRoute, path: '/returns', component: ReturnsPage })
const rewindRoute = new Route({ getParentRoute: () => layoutRoute, path: '/rewind', component: RewindPage })
const manifestsRoute = new Route({ getParentRoute: () => layoutRoute, path: '/manifests', component: ManifestsPage })
const bulletinsRoute = new Route({ getParentRoute: () => layoutRoute, path: '/bulletins', component: BulletinsPage })
const watchRoute = new Route({ getParentRoute: () => layoutRoute, path: '/watch', component: Watch })

const router = new Router({
  routeTree: rootRoute.addChildren([
    authRoute.addChildren([
      layoutRoute.addChildren([
        assignmentRoute,
        runsRoute,
        customersRoute,
        activeRoute,
        activeRunDetailsRoute,
        vehiclesRoute,
        podsRoute,
        paymentsRoute,
        returnsRoute,
        rewindRoute,
        manifestsRoute,
        bulletinsRoute,
        watchRoute
      ]),
      loginRoute
    ])
  ])
})

const App = () => {
  return (
    <ConfigProvider>
      <GlobalProvider>
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <NiceModal.Provider>
            <RouterProvider router={router} />
          </NiceModal.Provider>
        </QueryClientProvider>
      </GlobalProvider>
    </ConfigProvider>
  )
}

export default App