import NiceModal from '@ebay/nice-modal-react'

import DeleteModal from 'components/DeleteModal'
import ConfirmModal from 'components/ConfirmModal'
import LightboxModal from 'components/LightboxModal'

import AssignmentViewLinesModal from 'components/assignment/ViewLinesModal'

import RunsAddCustomerModal from 'components/runs/AddCustomerModal'
import RunsAddRunModal from 'components/runs/AddRunModal'
import RunsTimePickerModal from 'components/runs/TimePickerModal'

import VehiclesAddVehicleModal from 'components/vehicles/AddVehicleModal'
import VehiclesEditVehicleModal from 'components/vehicles/EditVehicleModal'

import BulletinsAddBulletinModal from 'components/bulletins/AddBulletinModal'
import BulletinsUpdateBulletinModal from 'components/bulletins/UpdateBulletinModal'
import BulletinsSendBulletinModal from 'components/bulletins/SendBulletinModal'

import PodsViewMapModal from 'components/pods/ViewMapModal'
import PodsViewSignatureModal from 'components/pods/ViewSignatureModal'

import RewindViewLinesModal from 'components/rewind/ViewLinesModal'

import ReturnsViewLinesModal from 'components/returns/ViewLinesModal'

import CustomersShowClusterModal from 'components/customers/ShowClusterModal'

NiceModal.register('deleteModal', DeleteModal)
NiceModal.register('confirmModal', ConfirmModal)
NiceModal.register('lightboxModal', LightboxModal)

NiceModal.register('assignmentViewLinesModal', AssignmentViewLinesModal)

NiceModal.register('runsAddCustomerModal', RunsAddCustomerModal)
NiceModal.register('runsAddRunModal', RunsAddRunModal)
NiceModal.register('runsTimePickerModal', RunsTimePickerModal)

NiceModal.register('vehiclesAddVehicleModal', VehiclesAddVehicleModal)
NiceModal.register('vehiclesEditVehicleModal', VehiclesEditVehicleModal)

NiceModal.register('bulletinsAddBulletinModal', BulletinsAddBulletinModal)
NiceModal.register('bulletinsUpdateBulletinModal', BulletinsUpdateBulletinModal)
NiceModal.register('bulletinsSendBulletinModal', BulletinsSendBulletinModal)

NiceModal.register('podsViewMapModal', PodsViewMapModal)
NiceModal.register('podsViewSignatureModal', PodsViewSignatureModal)

NiceModal.register('rewindViewLinesModal', RewindViewLinesModal)

NiceModal.register('returnsViewLinesModal', ReturnsViewLinesModal)

NiceModal.register('customersShowClusterModal', CustomersShowClusterModal)