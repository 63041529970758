import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'
import { CheckIcon, ClockIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import dayjs from 'dayjs'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import ModalContainer from 'components/ModalContainer'
import { Button, Card } from 'components/gsys-ui'
import { useAddTimeToRun } from 'util/mutations'


const TimePickerModal = NiceModal.create(({ runId, scheduleId }) => {
  const modal = useModal()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [time, setTime] = useState(moment().startOf('day'))
  const mutation = useAddTimeToRun()

  const handleSubmit = async (res) => {
    setSubmitting(true)

    try {
      await mutation.mutateAsync({ runId, scheduleId, time })
    } catch (e) {

    }

    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[350px]">
        <Card.Title Icon={ClockIcon}>Add departure time to run</Card.Title>
        <Card.Body>
          <div className="space-y-2">
            {
              errorText && (
                <div className="text-red-500">{JSON.stringify(errorText)}</div>
              )
            }
            <div className="py-2">
              <TimePicker
                defaultValue={time}
                onChange={setTime}
                showSecond={false}
                minuteStep={5}
                allowEmpty={false}
                inputClassName="text-md"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Cancel</Button>
              <Button Icon={CheckIcon} onClick={handleSubmit} variant="green" loading={isSubmitting}>Submit</Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default TimePickerModal