import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Grid, Col, Card, MultiButton, DraggableTable, Spinner, Button, ButtonInput, Input, Badge } from 'components/gsys-ui'
import { useInvoices, useTripInvoices, useTripDrops, useTrips, useUnallocatedCustInvs, useUnallocatedCust, useFrozenInvoiceCount } from 'util/queries'
import moment from 'moment'
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, EllipsisVerticalIcon, ExclamationCircleIcon, ListBulletIcon, MagnifyingGlassIcon, TrashIcon, TruckIcon } from '@heroicons/react/24/outline'
import { useMoveInvoice, useUpdateInvoiceStatus, useUpdateTripStatus } from 'util/mutations'
import DraggableMultiButton from 'components/assignment/DraggableMultiButton'
import Countdown from 'components/assignment/Countdown'
import EmptyMsg from 'components/EmptyMsg'
import Map from 'components/Map'
import MechanicIcon from 'components/MechanicIcon'
import { arrayGroupByObjectKey } from 'util/helpers'
import NiceModal from '@ebay/nice-modal-react'

const FrozenButton = () => {
  const { isLoading, isError, error, data } = useFrozenInvoiceCount()

  return (
    <span>
      Frozen
      {
        data && data > 0 ? (
          <span className="pl-1">
            <Badge variant="red" rounded>{data}</Badge>
          </span>
        ) : null
      }
    </span>
  )
}

const statusMultiOpt = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'In progress',
    value: 'inProgress'
  }
]

const invoiceMultiOpt = [
  {
    label: 'On board',
    value: 'onBoard'
  },
  {
    label: 'Unallocated',
    value: 'unallocated'
  },
  {
    label: <FrozenButton />,
    value: 'frozen'
  }
]

const tripTodayOpt = [
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'All',
    value: 'all'
  }
]

const mapDefaultProps = {
  center: {
    lat: 69,
    lng: 69
  },
  zoom: 11
}

const Assignment = () => {
  const moveInvoiceMutation = useMoveInvoice()
  const updateInvoiceStatusMutation = useUpdateInvoiceStatus()

  const [invoiceMulti, setInvoiceMulti] = useState('onBoard')
  const [statusMulti, setStatusMulti] = useState('pending')
  const [tripsMulti, setTripsMulti] = useState('today')
  const [selectedTrip, setSelectedTrip] = useState(null)
  const [hoveredTrip, setHoveredTrip] = useState(null)
  const [hoveredButton, setHoveredButton] = useState(null)
  const [isDragging, setDragging] = useState(false)
  const [invLoading, setInvLoading] = useState(null)
  const [invsGrouped, setInvsGrouped] = useState(true)
  const mutationLoading = moveInvoiceMutation.isLoading || updateInvoiceStatusMutation.isLoading

  const handleSelectTrip = (trip) => {
    setSelectedTrip(trip)
    setInvoiceMulti('onBoard')
  }

  const handleDragEnd = async (inv) => {
    setDragging(false)
    if (!hoveredTrip && !hoveredButton) return
    setInvLoading(inv.Document)

    try {
      if (hoveredTrip) {
        if (invoiceMulti === 'onBoard') {
          moveInvoiceMutation.mutate({
            docNo: inv.Document,
            destTrip: hoveredTrip,
            source: selectedTrip._id
          })
        } else {
          moveInvoiceMutation.mutate({
            docNo: inv.Document,
            destTrip: hoveredTrip,
            source: invoiceMulti
          })
        }
      } else if (hoveredButton === 'onBoard') {
        moveInvoiceMutation.mutate({
          docNo: inv.Document,
          destTrip: selectedTrip._id,
          source: invoiceMulti
        })
      } else if (hoveredButton === 'unallocated') {
        updateInvoiceStatusMutation.mutate({
          docNo: inv.Document,
          status: 'unallocated',
          source: invoiceMulti === 'onBoard' ? selectedTrip._id : invoiceMulti,
          acct: inv.Acct
        })
      } else if (hoveredButton === 'frozen') {
        updateInvoiceStatusMutation.mutate({
          docNo: inv.Document,
          status: 'frozen',
          source: invoiceMulti === 'onBoard' ? selectedTrip._id : invoiceMulti,
          acct: inv.Acct
        })
      }
    } catch (e) {
      console.error(e)
    }

    setHoveredTrip(null)
    setHoveredButton(null)
  }

  return (
    <Grid gap={7} className="h-full">
      <Col sm={24} md={10} xl={8} xxl={5}>
        {/*Button onClick={() => {
          haxios.post(
            `/factor/addIHeads`,
            [
              {
                Document: "TEST32",
                Acct: "234ARKLOW",
                Branch: "Top Part Arklow",
                DateTime: Date.now(),
                DelAdd: "COD, COD, COD",
                Goods: '6',
                Pcode: "COD",
                Prefix: "I"
              }
            ]
          )
        }}>
          Post IHead
      </Button>*/}
        <div className="relative h-full">
          <div className="flex absolute inset-0 flex-col">
            <div className="flex mb-2 space-x-2 flex-0">
              <MultiButton
                value={statusMulti}
                options={statusMultiOpt}
                onChange={setStatusMulti}
              />
              <MultiButton
                value={tripsMulti}
                options={tripTodayOpt}
                onChange={setTripsMulti}
              />
            </div>
            <div className="overflow-y-scroll flex-1 pr-2">
              <TripList
                selectedTrip={selectedTrip}
                setSelectedTrip={handleSelectTrip}
                hoveredTrip={hoveredTrip}
                setHoveredTrip={setHoveredTrip}
                isDragging={isDragging}
                statusMulti={statusMulti}
                tripsMulti={tripsMulti}
              />
            </div>
          </div>

        </div>
      </Col>
      <Col sm={24} md={14} xl={10} xxl={8}>
        <div className="flex flex-col items-end space-y-2 h-full">
          <div>
            <DraggableMultiButton
              value={invoiceMulti}
              options={invoiceMultiOpt}
              onChange={setInvoiceMulti}
              isDragging={isDragging}
              hoveredButton={hoveredButton}
              setHoveredButton={setHoveredButton}
            />
          </div>
          {
            invoiceMulti === 'onBoard' ? (
              <OnBoardCard
                selectedTrip={selectedTrip}
                setSelectedTrip={setSelectedTrip}
                setDragging={setDragging}
                handleDragEnd={handleDragEnd}
                invLoading={invLoading}
                mutationLoading={mutationLoading}
                invsGrouped={invsGrouped}
                setInvsGrouped={setInvsGrouped}
              />
            ) : invoiceMulti === 'unallocated' ? (
              <UnallocatedCard
                setDragging={setDragging}
                handleDragEnd={handleDragEnd}
                invLoading={invLoading}
                mutationLoading={mutationLoading}
                invsGrouped={invsGrouped}
                setInvsGrouped={setInvsGrouped}
              />
            ) : invoiceMulti === 'frozen' ? (
              <FrozenCard
                setDragging={setDragging}
                handleDragEnd={handleDragEnd}
                invLoading={invLoading}
                mutationLoading={mutationLoading}
                invsGrouped={invsGrouped}
                setInvsGrouped={setInvsGrouped}
              />
            ) : null
          }
        </div>
      </Col>
      <Col sm={24} xl={6} xxl={11}>
        <MapCard tripId={selectedTrip?._id} />
      </Col>
    </Grid>
  )
}

const MapCard = ({ tripId }) => {
  const { isLoading, isError, error, data } = useTripDrops(tripId, { enabled: !!tripId })
  const custWithLoc = data ? data.filter((cust) => cust.Loc !== null) : []
  const points = data ? custWithLoc.map((cust) => cust.Loc) : []

  return (
    <Map points={points}>
      {
        data && custWithLoc.map((el) => (
          <MechanicIcon
            key={el.Acct}
            lat={el.Loc.lat}
            lng={el.Loc.lon}
            name={el.Name}
          />
        ))
      }
    </Map>
  )
}

const TripList = ({ selectedTrip, setSelectedTrip, tripsMulti, hoveredTrip, setHoveredTrip, isDragging, statusMulti }) => {
  const { isLoading, isError, error, data } = useTrips()

  if (isLoading) return <Spinner size={30} cont={true} />
  if (!data || data.length === 0) return <EmptyMsg>There are no trips to display.</EmptyMsg>

  const dataWithTrips = data.map((run) => {
    return {
      ...run,
      Departures: run.Departures.map((departure) => {
        const departuresInFuture = departure.Trips.filter((dep) => {
          return ['pending', 'inProgress'].includes(dep.Status) && moment(dep.DepartureTime).isAfter(moment())
        })

        if (departuresInFuture.length === 0) return {
          ...departure,
          Trips: [
            ...departure.Trips,
            {
              _id: `${run._id},${departure._id}`,
              DepartureTime: departure.FutureDepartureTimes[0],
              Status: 'pending',
              Invoices: [],
              LinesQty: 0,
              LinesValue: 0,
              Accts: []
            }]
        }

        return departure
      })
    }
  })

  const dataSorted = dataWithTrips.sort((a, b) => {
    const aTimes = a.Departures.map((d) => d.FutureDepartureTimes[0]).sort((a, b) => moment(a).diff(moment(b)))
    const bTimes = b.Departures.map((d) => d.FutureDepartureTimes[0]).sort((a, b) => moment(a).diff(moment(b)))
    return moment(aTimes[0]).diff(moment(bTimes[0]))
  })

  return (
    <div className="space-y-2">
      {
        dataSorted.map((run) => (
          <RunCard
            key={run._id}
            run={run}
            selectedTrip={selectedTrip}
            setSelectedTrip={setSelectedTrip}
            isDragging={isDragging}
            hoveredTrip={hoveredTrip}
            setHoveredTrip={setHoveredTrip}
            tripsMulti={tripsMulti}
            statusMulti={statusMulti}
          />
        ))
      }
    </div>
  )
}

const RunCard = ({ run, tripsMulti, statusMulti, isDragging, hoveredTrip, setHoveredTrip, selectedTrip, setSelectedTrip }) => {
  const trips = run.Departures
    .map((d) => d.Trips)
    .flat()
    .map((trip) => ({ ...trip, RunName: run.Name }))
    .sort((a, b) => moment(a.DepartureTime).diff(moment(b.DepartureTime)))

  const tripsFiltered = tripsMulti === 'today' ? (
    trips.filter((trip) => moment(trip.DepartureTime).isSame(new Date(), 'day') && trip.Status === statusMulti)
  ) : trips.filter((trip) => trip.Status === statusMulti)

  if (tripsFiltered.length === 0) return null

  return (
    <div>
      <div className="flex items-center p-1 text-lg font-bold"><TruckIcon className="mr-1 w-5 h-5" />Run {run.Name}</div>
      <div className="pl-3 ml-3 space-y-2 border-l border-neutral-400">
        {
          tripsFiltered.map((trip) => (
            <TripCard
              key={trip._id}
              trip={trip}
              isDragging={isDragging}
              hoveredTrip={hoveredTrip}
              setHoveredTrip={setHoveredTrip}
              active={selectedTrip?._id === trip._id}
              onClick={() => setSelectedTrip(trip)}
            />
          ))
        }
      </div>
    </div>
  )
}

const TripCard = ({ trip, isDragging, hoveredTrip, setHoveredTrip, active = false, onClick }) => {
  // trip is not allowed for drops if it's the currently selected trip
  const fadeOut = isDragging && isDragging.DDTripId === trip._id
  const readyForDrop = hoveredTrip === trip._id && !fadeOut

  return (
    <div
      className={clsx(
        'transition-opacity select-none',
        fadeOut && 'opacity-40'
      )}
      onDragEnter={(e) => {
        // if this trip isn't allowed don't do anything
        if (fadeOut) {
          setHoveredTrip(null)
        } else {
          setHoveredTrip(trip._id)
        }
      }}
      onDragExit={() => setHoveredTrip(null)}
      onDragOver={(e) => {
        // prevents the ghost from sliding back to the original element
        if (!fadeOut) e.preventDefault()
      }}
    >
      <Card
        hover={!active}
        onClick={onClick}
        className={clsx(
          '!transition-colors',
          readyForDrop && '!bg-blue-100 !border-blue-300 !shadow-lg',
          active && '!border-green-600 !border-[2px] !shadow-lg',
          !active && 'p-[1px]',
        )}
      >
        <div className="p-1">
          <div className="mb-1 font-bold">{trip.RunName}</div>
          <div className="flex justify-between">
            <div className="flex flex-col items-center">
              <div className="text-sm select-none">DROPS</div>
              <div className="text-lg font-bold leading-none">{trip.Accts.length}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-sm select-none">INVOICES</div>
              <div className="text-lg font-bold leading-none">{trip.Invoices.length}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-sm select-none">ITEMS</div>
              <div className="text-lg font-bold leading-none">{trip.LinesQty}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-sm select-none">VALUE</div>
              <div className="text-lg font-bold leading-none">£{trip.LinesValue.toFixed(2)}</div>
            </div>
          </div>
        </div>
        <div className="ml-[-2px] mr-[-2px] mb-[-2px] bg-neutral-500 rounded-b-md px-2 flex justify-between text-white text-lg relative">
          <div>
            <span className="mr-2 select-none text-[15px]">DEPARTS</span>
            <span className="font-bold">{moment(trip.DepartureTime).format('HH:mm')}</span>
          </div>
          <div>
            <Countdown time={trip.DepartureTime} />
          </div>
          <div className={clsx(
            'flex absolute inset-0 justify-center items-center rounded-b-md bg-neutral-500 opacity-0 transition-opacity',
            readyForDrop && 'opacity-100'
          )}>
            MOVE INVOICE
          </div>
        </div>
      </Card>
    </div>
  )
}


const OnBoardCard = ({ selectedTrip, setSelectedTrip, setDragging, handleDragEnd, invLoading, mutationLoading, invsGrouped, setInvsGrouped }) => {
  const { isLoading, isError, error, data } = useTripInvoices(selectedTrip?._id, { enabled: !!selectedTrip })
  const mutation = useUpdateTripStatus()

  if (!selectedTrip) return <EmptyMsg>Please select a trip.</EmptyMsg>
  if (isLoading) return <Spinner size={30} cont={true} />

  const handleDelete = async () => {
    await mutation.mutateAsync({ tripId: selectedTrip._id, status: 'void' })
    setSelectedTrip(null)
  }

  const isOverdue = moment(selectedTrip.DepartureTime).isBefore(moment())
  const dataGrouped = arrayGroupByObjectKey(data, 'Acct')

  return (
    <div className="relative flex-1 w-full">
      <Card className="flex absolute inset-0 flex-col">
        <Card.Title className="!text-[16px] !font-bold flex-none" bordered>
          <div>ON BOARD</div>
          <div className="flex items-center space-x-2">
            <div>
              {selectedTrip.RunName} - {moment(selectedTrip.DepartureTime).format('ddd Do MMM HH:mm')}
            </div>
            {
              isOverdue && (
                <Button
                  small
                  variant="red"
                  onClick={() => NiceModal.show('deleteModal', { name: 'trip', handleDelete })}>
                  <TrashIcon className="mr-1 w-4 h-4" />Delete
                </Button>
              )
            }
            <Button
              small
              variant={invsGrouped ? 'blue' : 'white'}
              onClick={() => setInvsGrouped(!invsGrouped)}
            >
              Group
            </Button>
          </div>
        </Card.Title>
        <Card.Body className="overflow-y-scroll flex-1" noPad>
          {
            invsGrouped ? (
              Object.keys(dataGrouped).sort().map((key) => (
                <InvTableGroup
                  key={key}
                  data={dataGrouped[key]}
                  setDragging={setDragging}
                  handleDragEnd={handleDragEnd}
                  invLoading={invLoading}
                  mutationLoading={mutationLoading}
                />
              ))
            ) : (
              <InvTable
                data={data}
                onDragStart={setDragging}
                onDragEnd={handleDragEnd}
                invLoading={invLoading}
                mutationLoading={mutationLoading}
              />
            )
          }
        </Card.Body>
      </Card>
    </div>
  )
}

const UnallocatedCard = ({ setDragging, handleDragEnd, invLoading, mutationLoading, invsGrouped, setInvsGrouped }) => {
  const { isLoading, isError, error, data } = useUnallocatedCust({ enabled: invsGrouped === true })
  const { isLoading: invsIsLoading, isError: invsIsError, error: invsError, data: invsData } = useInvoices('unallocated', { enabled: invsGrouped === false })
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch('')
  }, [invsGrouped])

  const dataFiltered = invsGrouped ? (
    data ? data.filter((row) => `${row.Acct} ${row.Name}`.toLowerCase().includes(search.toLowerCase().trim())) : []
  ) : (
    invsData ? invsData.filter((row) => `${row.Document}`.toLowerCase().includes(search.toLowerCase().trim())) : []
  )

  return (
    <div className="relative flex-1 w-full">
      <Card className="flex absolute inset-0 flex-col">
        <Card.Title className="!text-[16px] !font-bold flex-none" bordered>
          <div>UNALLOCATED</div>
          <div className="flex items-center space-x-2">
            <Input
              placeholder="Type to search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div>
              <Button
                small
                variant={invsGrouped ? 'blue' : 'white'}
                onClick={() => setInvsGrouped(!invsGrouped)}
              >
                Group
              </Button>
            </div>
          </div>
        </Card.Title>
        <Card.Body className="overflow-y-scroll flex-1" noPad>
          {
            isLoading || invsIsLoading ? (
              <Spinner size={30} cont={true} />
            ) : (
              invsGrouped ? (
                dataFiltered.map((row) => (
                  <InvTableGroupLoad
                    key={row.Acct}
                    cust={row}
                    setDragging={setDragging}
                    handleDragEnd={handleDragEnd}
                    invLoading={invLoading}
                    mutationLoading={mutationLoading}
                  />
                ))
              ) : (
                <InvTable
                  data={dataFiltered}
                  onDragStart={setDragging}
                  onDragEnd={handleDragEnd}
                  invLoading={invLoading}
                  mutationLoading={mutationLoading}
                />
              )
            )
          }
        </Card.Body>
      </Card>
    </div>
  )
}

const FrozenCard = ({ setDragging, handleDragEnd, invLoading, mutationLoading, invsGrouped, setInvsGrouped }) => {
  const { isLoading, isError, error, data } = useInvoices('frozen')

  if (isLoading || !data) return <Spinner size={30} cont={true} />

  const dataGrouped = arrayGroupByObjectKey(data, 'Acct')

  return (
    <div className="relative flex-1 w-full">
      <Card className="flex absolute inset-0 flex-col">
        <Card.Title className="!text-[16px] !font-bold flex-none" bordered>
          <div>FROZEN</div>
          <Button
            small
            variant={invsGrouped ? 'blue' : 'white'}
            onClick={() => setInvsGrouped(!invsGrouped)}
          >
            Group
          </Button>
        </Card.Title>
        <Card.Body className="overflow-y-scroll flex-1" noPad>
          {
            invsGrouped ? (
              Object.keys(dataGrouped).sort().map((key) => (
                <InvTableGroup
                  key={key}
                  data={dataGrouped[key]}
                  setDragging={setDragging}
                  handleDragEnd={handleDragEnd}
                  invLoading={invLoading}
                  mutationLoading={mutationLoading}
                />
              ))
            ) : (
              <InvTable
                data={data}
                onDragStart={setDragging}
                onDragEnd={handleDragEnd}
                invLoading={invLoading}
                mutationLoading={mutationLoading}
              />
            )
          }
        </Card.Body>
      </Card>
    </div>
  )
}

const InvTable = (props) => {
  const columns = useMemo(() => [
    {
      id: 'dragHandle',
      cellSize: 17,
      cellPad: false,
      cell: ({ row }) => (
        props.mutationLoading && props.invLoading === row.original.Document ? (
          <div className="flex absolute inset-0 justify-center items-center">
            <Spinner size={14} color="rgb(59, 130, 246)" />
          </div>
        ) : (
          <div className="absolute inset-0 transition-colors group-hover:bg-blue-100">
            <EllipsisVerticalIcon className="absolute top-[7px] left-0.5 w-4 h-4" />
            <EllipsisVerticalIcon className="absolute top-[7px] -left-0.5 w-4 h-4" />
          </div>
        )
      )
    },
    {
      header: 'DATE',
      id: 'date',
      cellSize: 130,
      cell: ({ row }) => moment(row.original.DateTime).format('DD/MM/YY HH:mm')
    },
    {
      header: 'INVOICE',
      accessorKey: 'Document',
    },
    {
      header: 'ITEMS',
      accessorKey: 'LinesQty'
    },
    {
      header: 'VALUE',
      accessorKey: 'Goods',
      cell: ({ row }) => row.original.Goods ? (
        `£${parseFloat(row.original.Goods).toFixed(2)}`
      ) : (
        '£0.00'
      )
    },
    {
      header: '',
      accessorKey: 'warn',
      cellSize: 1,
      cellPad: false,
      cell: ({ row }) => row.original.Loc ? null : (
        <div className="flex items-center">
          <ExclamationCircleIcon className="mx-1 w-5 h-5 text-red-500" />
        </div>
      )
    },
    {
      header: '',
      accessorKey: 'lines',
      cellSize: 1,
      disableSortBy: true,
      cellPad: false,
      cell: ({ row }) => (
        <div className="flex items-center px-1 h-full">
          <Button
            variant="rounded"
            tiny
            onClick={() => NiceModal.show('assignmentViewLinesModal', { docNo: row.original.Document })}
            Icon={ListBulletIcon}
          />
        </div>
      )
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <DraggableTable
      cols={columns}
      data={data}
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
      draggableName="invRow"
      isPaginated={false}
    />
  )
}

const InvTableGroup = ({ data, startState = true, setDragging, handleDragEnd, invLoading, mutationLoading }) => {
  const [showTable, setShowTable] = useState(startState)

  return (
    <div className="border-b border-grey-400">
      <div
        className="flex justify-between items-center p-1 font-bold border-t cursor-pointer select-none bg-neutral-100 border-t-neutral-200"
        onClick={() => setShowTable(!showTable)}
      >
        <div className="text-[15px] truncate px-1" title={data[0].Name}>{data[0].Acct} - {data[0].Name}</div>
        <div className="flex flex-none items-center space-x-2">
          <div className="text-sm">{data.length} INVOICE{data.length !== 1 && 'S'}</div>
          <Button variant="rounded" small>
            {
              showTable ? (
                <ChevronDoubleUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDoubleDownIcon className="w-5 h-5" />
              )
            }
          </Button>
        </div>
      </div>
      {
        showTable && (
          <InvTable
            data={data}
            onDragStart={setDragging}
            onDragEnd={handleDragEnd}
            invLoading={invLoading}
            mutationLoading={mutationLoading}
          />
        )
      }
    </div>
  )
}

const InvTableGroupLoad = ({ cust, startState = false, setDragging, handleDragEnd, invLoading, mutationLoading }) => {
  const [showTable, setShowTable] = useState(startState)
  const { isLoading, isError, error, data } = useUnallocatedCustInvs(cust.Acct, { enabled: showTable })

  return (
    <div className="border-b border-grey-400">
      <div
        className="flex justify-between items-center p-1 font-bold border-t cursor-pointer select-none bg-neutral-100 border-t-neutral-200"
        onClick={() => setShowTable(!showTable)}
      >
        <div className="text-[15px] truncate px-1" title={cust.Name}>{cust.Acct} - {cust.Name}</div>
        <div className="flex flex-none items-center space-x-2">
          <div className="text-sm">{cust.Count} INVOICE{cust.Count !== 1 && 'S'}</div>
          <Button variant="rounded" small loading={isLoading}>
            {
              showTable ? (
                <ChevronDoubleUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDoubleDownIcon className="w-5 h-5" />
              )
            }
          </Button>
        </div>
      </div>
      {
        showTable && data && (
          <InvTable
            data={data}
            onDragStart={setDragging}
            onDragEnd={handleDragEnd}
            invLoading={invLoading}
            mutationLoading={mutationLoading}
          />
        )
      }
    </div>
  )
}

export default Assignment