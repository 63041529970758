import moment from 'moment';
import { toast } from 'react-hot-toast'

// https://stackoverflow.com/a/18650249
export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const objectToFormData = (obj) => {
  let formData = new FormData()

  for (const key in obj) {
    formData.append(key, obj[key])
  }

  return formData
}

export const objectToUrlEncoded = (obj) => {
  let body = []

  for (const key in obj) {
    var encodedKey = encodeURIComponent(key)
    var encodedValue = encodeURIComponent(obj[key])
    body.push(encodedKey + '=' + encodedValue)
  }

  const ret = body.join('&')
  return ret
}

export const successToast = (msg) => toast.success(
  msg,
  {
    position: 'bottom-center',
    style: {
      borderRadius: '6px',
      background: '#333',
      color: '#fff',
    }
  }
)

const GOOGLE_TILE_SIZE = 256;

function latLng2World({ lat, lng }) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  y = y < 0 // eslint-disable-line
    ? 0
    : y > 1
      ? 1
      : y;
  return { x, y };
}

function world2LatLng({ x, y }) {
  const n = Math.PI - 2 * Math.PI * y;

  // TODO test that this is faster
  // 360 * Math.atan(Math.exp((180 - y * 360) * Math.PI / 180)) / Math.PI - 90;
  return {
    lat: Math.round(((180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)))) * 10000000) / 10000000,
    lng: Math.round((x * 360 - 180) * 10000000) / 10000000
  };
}

const log2 = Math.log2 ? Math.log2 : (x) => Math.log(x) / Math.LN2;

export function fitNwSe(nw, se, width, height) {
  width = Math.round(width * 0.9)
  height = Math.round(height * 0.9)
  const EPS = 0.000000001;
  const nwWorld = latLng2World(nw);
  const seWorld = latLng2World(se);
  const dx =
    nwWorld.x < seWorld.x ? seWorld.x - nwWorld.x : 1 - nwWorld.x + seWorld.x;
  const dy = seWorld.y - nwWorld.y;

  if (dx <= 0 && dy <= 0) {
    return null;
  }

  const zoomX = log2(width / GOOGLE_TILE_SIZE / Math.abs(dx));
  const zoomY = log2(height / GOOGLE_TILE_SIZE / Math.abs(dy));
  const zoom = Math.floor(EPS + Math.min(zoomX, zoomY))
  const scale = Math.pow(2, zoom);
  const markerHeight = 70 / scale / GOOGLE_TILE_SIZE

  // TODO find center just unproject middle world point
  const middle = {
    x: nwWorld.x < seWorld.x // eslint-disable-line
      ? 0.5 * (nwWorld.x + seWorld.x)
      : nwWorld.x + seWorld.x - 1 > 0
        ? 0.5 * (nwWorld.x + seWorld.x - 1)
        : 0.5 * (1 + nwWorld.x + seWorld.x),
    y: 0.5 * (nwWorld.y + seWorld.y),
  };

  const halfW = width / scale / GOOGLE_TILE_SIZE / 2;
  const halfH = height / scale / GOOGLE_TILE_SIZE / 2;

  const newNW = world2LatLng({
    x: middle.x - halfW,
    y: middle.y - halfH,
  });

  const newSE = world2LatLng({
    x: middle.x + halfW,
    y: middle.y + halfH,
  });

  return {
    center: world2LatLng(middle),
    zoom,
    newBounds: {
      nw: newNW,
      se: newSE,
    },
  };
}

export function getTimeDifference(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  let duration = moment.duration(end.diff(start));

  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) - hours * 60;
  const seconds =
    Math.floor(duration.asSeconds()) - hours * 3600 - minutes * 60;

  let result = '';

  // Append hours if they're greater than zero
  if (hours > 0) {
    result += `${hours}h `;
  }

  // Append minutes if they're greater than zero
  if (minutes > 0) {
    result += `${minutes}m `;
  }

  // Always append seconds
  //result += `${seconds}s`;

  return result.trim(); // trim() to remove any extra spaces
}

export const sortByKeyString = (key) => (a, b) => {
  if (a[key] < b[key]) {
    return -1
  }

  if (a[key] > b[key]) {
    return 1
  }

  return 0
}

export const arrayGroupByObjectKey = (arr, key) => {
  return arr.reduce((acc, cur) => {
    if (!acc[cur[key]]) acc[cur[key]] = []
    acc[cur[key]].push(cur)
    return acc
  }, {})
}