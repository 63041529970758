import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import moment from 'moment'

const Countdown = React.memo(({ time }) => {
  const calculateTimeLeft = () => {
    const duration = moment.duration(moment(time).diff(moment()))
    return { days: duration.days(), hours: duration.hours(), minutes: duration.minutes(), seconds: duration.seconds() }
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const isOverdue = Object.values(timeLeft).some((n) => n < 0)
  const isToday = moment(time).isSame(new Date(), 'day')

  return (
    <>
      {
        isOverdue ? (
          <span className="mr-2 select-none text-[15px]"></span>
        ) : !isToday ? (
          <span className="mr-2 select-none text-[15px]"></span>
        ) : (
          <span className="mr-2 select-none text-[15px]">IN</span>
        )
      }

      <span className={clsx('font-bold', isOverdue && 'text-red-300')}>
        {
          !isToday ? (
            moment(time).format('ddd Do MMM')
          ) : (
            [timeLeft.hours, timeLeft.minutes, timeLeft.seconds]
              .map((v) => String(Math.abs(v)).padStart(2, '0'))
              .join(':')
          )
        }
      </span>
    </>
  )
})

export default Countdown