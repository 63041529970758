import clsx from 'clsx'
import { Button } from 'components/gsys-ui'
import s from 'components/gsys-ui/MultiButton/MultiButton.module.scss'
import { useState } from 'react'

const DraggableMultiButton = ({ value, options, onChange, isDragging, hoveredButton, setHoveredButton }) => {
  return (
    <div className={s.container}>
      {
        options.map((o, ind) => {
          const isActive = value === o.value
          const readyForDrop = !isActive && isDragging && hoveredButton === o.value

          return (
            <Button
              key={ind}
              variant="rounded"
              onClick={() => onChange(o.value)}
              onDragEnter={() => {
                if (isActive) {
                  setHoveredButton(null)
                } else {
                  setHoveredButton(o.value)
                }
              }}
              onDragExit={() => setHoveredButton(null)}
              onDragOver={(e) => {
                if (!isActive) e.preventDefault()
              }}
              disabled={isActive}
              className={clsx(
                s.button, '!transition-all',
                isActive && s.active,
                isDragging && isActive && 'opacity-40',
                readyForDrop && '!bg-blue-100'
              )}
            >
              {o.label}
            </Button>
          )
        })
      }
    </div>
  )
}

export default DraggableMultiButton